/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import { Select, Avatar, Row, Col, Card, Divider, Button } from 'antd';
const { Meta } = Card;
const { Option } = Select;
import { PageContainer } from '@ant-design/pro-layout';
import ResponsiveContainer from '../responsive-container/responsive-container';
import { useI18n } from 'react-simple-i18n';
import { API_ENDPOINT } from '../../constants/api_endpoints';
interface Project {
  name: string;
  image_url: string;
  os_link: string;
}
interface IHash {
  [details: string]: Project;
}
const AerfaIndex = () => {
  const { t } = useI18n();
  const [data, setData] = useState(null);
  const [dicData, setDicData] = useState<IHash>({});
  const [searchResult, setSearchResult] = useState([]);
  const [currentProject, setCurrentProject] = useState<Project>(null);
  // const [source, setSource] = useState('bluechips');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      //const response = await fetch(`${API_ENDPOINT('index')}/v1/top600?project=all`);
      const response = await fetch('https://35rure11h9.execute-api.us-west-1.amazonaws.com/getMetadata', { mode: 'cors' });
      const data = await response.json();
      const parsed = Object.entries(data).map((pair) => {
        const ret: any = pair[1];
        ret['name'] = pair[0];
        return ret;
      });
      setDicData(data);
      //console.log('response', response);
      setData(parsed);
      setLoading(false);
    };
    void getData();
  }, []);
  const handleChange = (value: string) => {
    //console.log('value', value);
    setCurrentProject(dicData[value]);
    const lookAlikes = async () => {
      setLoading(true);
      //const response = await fetch(`${API_ENDPOINT('index')}/v1/visual-search?project=${value}`);
      const response = await fetch(`https://dnp4tek6s8.execute-api.us-west-1.amazonaws.com/getNearest4?anchor=${value}`);
      if (response.status !== 200) {
        return;
      }
      const data = await response.json();
      setSearchResult(data);
      setLoading(false);
    };
    void lookAlikes();
    //console.log('searchResult', searchResult);
  };

  let droplists = null;
  if (loading === false) {
    droplists = data.map((item: any) => {
      return (
        <Option key={item['name']} value={item['name']} label={item['name']}>
          <div>
            <span role="img" aria-label={item['name']}>
              <Avatar size="small" src={item['image_url']} />
            </span>
            {item['name']}
          </div>
        </Option>
      );
    });
  }
  return (
    <ResponsiveContainer>
      <Select
        // mode="multiple"
        showSearch
        style={{ width: '100%' }}
        placeholder={t('lookalikes.select_one_project name')}
        // defaultValue={['china']}
        onChange={handleChange}
        optionLabelProp="label"
      >
        {droplists}
      </Select>
      {currentProject ?
        <div>
          <Divider orientation="left">{t('lookalikes.target_project')}</Divider>
          <Row gutter={16}>
            <Col span={6}>
              {currentProject ? <Card
                hoverable
                // style={{ width: 240 }}
                cover={<img alt={currentProject['name']} src={currentProject['image_url']} />}
              >
                <Meta description={<Button type="link" href={currentProject['os_link']} target='_blank'>{currentProject['name']}</Button>} />
              </Card> : null}
            </Col>
          </Row>
          <Divider orientation="left">{t('lookalikes.top_5_similar')}</Divider>
          <Row gutter={16}>
            {searchResult
              ? searchResult.map((item: any) => {
                return (
                  <Col key={item['name']} span={6}>
                    <Card
                      hoverable
                      // style={{ width: 240 }}
                      cover={<img alt={item['name']} src={item['image_url']} />}
                    >
                      <Meta description={<Button type="link" href={item['os_link']} target='_blank'>{item['name']}</Button>} />
                    </Card>
                  </Col>
                );
              })
              : null}
          </Row>
        </div> : null}
    </ResponsiveContainer>
  );
};

export default AerfaIndex;
