import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fdgPointsFetchedForUser,
  FdgPointsFetchedForUserPayload,
  LoginSuccessfulPayload,
  loginSuccessful,
  logoutSuccessful,
} from '../actions/user';
import { RootState } from '../store';

export const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginSuccessful, (state = {}, action: PayloadAction<LoginSuccessfulPayload>) => {
        const assets = action.payload.assets || [];
        let totalPoints = 0;
        for (const nftTypeItem of assets) {
          for (const nft of nftTypeItem.nfts) {
            totalPoints += parseFloat(nft.hodl_points);
          }
        }
        const fdgNfts = assets.find((asset) => asset.contractName === 'FDG')?.nfts || [];
        let profileImage;
        if (fdgNfts.length > 0) {
          const tokenId = fdgNfts[0].token_id;
          profileImage = `https://metadata.fortunedao.io/images/${tokenId as string}.png`;
        } else {
          // const tokenId = zenNfts[0].token_id;
          // profileImage = `https://metadata.fortunedao.io/images/${tokenId as string}.png`;
          profileImage = 'https://aerfa.io/zenlicious_logo.png';
        }
        return {
          ...state,
          assets,
          totalPoints,
          profileImage,
          wallet: {
            address: action.payload.address,
          },
        };
      })
      .addCase(logoutSuccessful, () => null)
      .addCase(fdgPointsFetchedForUser, (state: any, action: PayloadAction<FdgPointsFetchedForUserPayload>) => {
        if (!state) {
          return;
        }
        return {
          ...state,
          profileImage: action.payload.profileImage,
          collections: {
            fdg: {
              totalPoints: action.payload.totalPoints,
              tokens: action.payload.tokens,
            },
          },
        };
      });
  },
});

export default userSlice;
