import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const holderPoints = createApi({
  reducerPath: 'holderPoints',
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === 'production'
        ? 'https://prod-nft-points-data.s3.ap-southeast-2.amazonaws.com/'
        : ' https://develop-nft-points-data.s3-ap-southeast-2.amazonaws.com/',
  }),
  endpoints: (builder) => ({
    // for rankings page table
    getHolderPoints: builder.query<Array<FdgPointsData>, string>({
      query: (contract) => `holder/${contract}.json`,
      transformResponse: (response: any) => {
        const output: Array<FdgPointsData> = [];
        for (const ownerkey in response) {
          const tokens = [];
          let totalPoints = 0;
          for (const tokenKey in response[ownerkey]) {
            tokens.push(response[ownerkey][tokenKey]);
            totalPoints += response[ownerkey][tokenKey].hodl_points;
          }
          output.push({
            tokens,
            totalPoints,
            holderAddress: ownerkey,
          });
          output.sort((a, b) => b.totalPoints - a.totalPoints);
        }
        return output;
      },
    }),
  }),
});

export default holderPoints;
export const { useGetHolderPointsQuery } = holderPoints;
