import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../constants/api_endpoints';

const login = createApi({
  reducerPath: 'login',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT('points'),
  }),
  endpoints: (builder) => ({
    getLogin: builder.query<any, object>({
      query: (body) => ({
        url: '/account/login',
        method: 'POST',
        body,
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
  }),
});

export default login;
export const { useGetLoginQuery, useLazyGetLoginQuery } = login;
