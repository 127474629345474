const langData = {
  en_US: {
    nav: {
      home: 'Home',
      rank: 'Rankings',
      raffle: 'Raffle',
      holder_portal: 'Holder Portal',
      aerfa_index: 'Aerfa Index',
      fomo_fud_index: 'FOMO Index',
      lookalikes: 'Nft2Vec(beta)',
      classifier: '(Inaccurate)ImageClassifier',
      coming_soon: 'Comming soon',
      holder_only: 'Holder Only',
      lang: '中文',
      connect: 'Connect',
      disconnect: 'Disconnect',
      login: 'Login',
      signout: 'Sign out',
      user_sign_denied: 'User signature denied',
      user_sign_fail: 'User signature failed',
      user_sign_info: 'Please sign to login',
      user_logging_in: 'Successfully signed. Now logging in',
      login_success: 'Login successfully',
    },
    home: {
      ctaButton: 'Check it out!',
      tab1: 'Tab1',
      // prettier-ignore
      tab1_heading:
        'Tab1 heading',
      tab1_content: 'Tab1 content.',
      tab2: 'Tab2',
      // prettier-ignore
      tab2_heading:
        'Tab2 heading',
      tab2_content:
        // prettier-ignore
        'Tab2 content',
    },
    rank: {
      heading: 'HODL Points',
      // prettier-ignore
      tool_tips:
        'Every eligible NFT held in wallet will generate 1 HODL point per day. Each wallet can hold multiple NFTs at the same time, and points from all NFTs will stack.',
      address: 'Address',
      // prettier-ignore
      intro:
        'HODL points is the rewards points that NFT holders get by holding the specific NFT within their wallet. HODL points are calcualted regularly based on the public blockchain data of the NFT smart contract, holders do not need to claim them manually. The points is not transferrable or tradable, and will reset once the NFT was transferred or traded on secondary markets.',
      total_points: 'Total Points',
      winning_count: 'Raffle Winning Count',
      owned_nfts: 'Owned NFTs',
      available_points: 'Available Points For Raffle',
    },
    holder_portal: {
      nav_heading: 'Holder Portal',
      my_nfts: 'My NFTs',
      analytics: 'Analytics',
      reports: 'Reports',
      aerfa_bluechips_index: 'Bluechips Index',
      aerfa_emerging_index: 'Emerging Index',
      analytic_tips: 'Mobile Support Coming Soon',
      fdg: 'Fortune Dao Genesis',
      zenlicious: 'Zenlicious',
    },
    aerfa_index: {
      bluechips: 'Bluechips',
      emerging: 'Emerging',
      green_up: 'Green Up',
      red_up: 'Red Up',
      current: 'Current',
      interval: 'Interval',
      intervals: {
        '30 mins': '30 mins',
        '1 hour': '1 hour',
        '2 hours': '2 hours',
        '3 hours': '3 hours',
        '4 hours': '4 hours',
        '5 hours': '5 hours',
        '6 hours': '6 hours',
        '1 day': '1 day',
        '1 week': '1 week',
        '1 month': '1 month',
        '24 hours': '24 hours',
      },
      candlestick_chart: 'Candlestick Chart',
      fear_greed: 'Fear & Greed Index',
      fear: 'Fear',
      extreme_fear: 'Extreme Fear',
      neutral: 'Neutral',
      greed: 'Greed',
      extreme_greed: 'Extreme Greed',
      holdings: 'holdings',
      holdings_analysis: 'Holdings Analysis',
      open: 'open',
      close: 'close',
      high: 'high',
      low: 'low',
    },
    fomo_fud_index: {
      project: 'Project',
      fomo_fud_chart: 'Fomo/Fud chart',
      fomoIndexDescription: 'Fomo index',
      floor_price: 'Floor price',
      one_day_volume: 'Volume (24h)',
      one_day_change: 'Change (24h)',
      one_day_sales: 'Sales (24h)',
      one_day_average_price: 'Average Price (24h)',
      total_volume: 'Total Volume',
      total_supply: 'Total Supply',
      num_owners: 'Owners',
      average_price: 'Average Price Over Time',
      market_cap: 'Market Cap',
    },
    holder_portal_tool: {
      release_time: 'Release Time',
    },
    lookalikes: {
      select_one_project_name: 'select one project name',
      target_project: 'Anchor Image',
      top_5_similar: 'Top 4 Similar Looking Images',
    },
  },
  zh_CN: {
    nav: {
      home: '首页',
      rank: '排行榜',
      raffle: '抽奖',
      holder_portal: '持有者门户',
      aerfa_index: '阿尔法指数',
      fomo_fud_index: '恐慌贪婪指数',
      lookalikes: '视觉搜索 (beta 测试)',
      coming_soon: '即将开放',
      holder_only: '持有者可访问',
      lang: 'En',
      connect: '连接钱包',
      disconnect: '断开连接',
      login: '登录',
      signout: '退出',
      user_sign_denied: '用户拒绝签名',
      user_sign_fail: '用户签名失败',
      user_sign_info: '请签名以登录',
      user_logging_in: '签名成功，正在登录',
      login_success: '登录成功',
    },
    home: {
      ctaButton: '点击查看',
      tab1: '财神道',
      tab1_heading:
        '限量版财神道（FDG）是NFT Aerfa社区推出的首款会员+头像+工具类NFT系列，由666个制作精良的现代版财神主题NFT组成。',
      // prettier-ignore
      tab1_content:
        'FDG的发售价格为0.088ETH，持有FDG享受NFT Aerfa社区未来主导发行的所有NFT项目白名单，PFP头像，以及优先使用Aerfa社区自主开发的NFT大盘指数分析工具等权益。',
      tab2: '一膳斋',
      // prettier-ignore
      tab2_heading:
        'Zenlicious是Aerfa社区第一次尝试与第三方联合发行NFT产品，NFT领域第一款美食视频类NFT，总供给为888个。',
      // prettier-ignore
      tab2_content:
        'Zenlicious的合作方一膳斋是中国著名美食短视频品牌，拥有全网330万粉丝，200万平均单集播放量和3亿全网总播放量。与康师傅、McCormick等中外知名品牌有广泛的合作关系。Zenlicious在弘扬中华美食文化的同时，更希望向世界传达以禅观物、天人合一的中国传统美学观念。 持有Zenlicious的权益包括Aerfa社区会员权益，以及Zenlicious短视频商业IP使用权。',
    },
    rank: {
      heading: 'HODL积分',
      // prettier-ignore
      tool_tips:
        '持有者钱包里面的每一个NFT每日可以获得 1 HODL积分。每个钱包地址可以同时持有多个NFT，并且所有NFT的积分可以叠加。',
      address: '钱包地址',
      // prettier-ignore
      intro:
        'HODL积分是一种NFT持有者通过在钱包持有特定NFT来获得的奖励积分。该积分的计算100%基于区块链上NFT智能合约的公开数据，用户无需手动领取积分。HODL积分无法转移和交易，当NFT在链上转移，或者二级交易市场出售的时候，属于该NFT的积分会清零。',
      total_points: '总积分',
      winning_count: '中奖次数',
      owned_nfts: '持有NFT',
      available_points: '可用抽奖积分',
    },
    holder_portal: {
      nav_heading: '持有者面板',
      my_nfts: '我的NFT',
      analytics: '数据分析',
      reports: '报告',
      aerfa_bluechips_index: '阿尔法蓝筹指数',
      aerfa_emerging_index: '阿尔法新兴指数',
      analytic_tips: '该功能手机端即将上线',
      fdg: '财神道',
      zenlicious: '一善斋',
    },
    aerfa_index: {
      bluechips: '蓝筹',
      emerging: '新兴',
      green_up: '红跌绿涨',
      red_up: '红涨绿跌',
      current: '当前',
      interval: '周期',
      intervals: {
        '30 mins': '30 分钟',
        '1 hour': '1 小时',
        '2 hours': '2 小时',
        '3 hours': '3 小时',
        '4 hours': '4 小时',
        '5 hours': '5 小时',
        '6 hours': '6 小时',
        '1 day': '1 天',
        '1 week': '1 周',
        '1 month': '1 月',
        '24 hours': '24 小时',
      },
      candlestick_chart: 'k线图',
      fear_greed: '恐慌贪婪指数',
      fear: '恐慌',
      extreme_fear: '极度恐慌',
      neutral: '正常',
      greed: '贪婪',
      extreme_greed: '极度贪婪',
      holdings: '成分股占比',
      holdings_analysis: '成分股分析图',
      open: '开盘价',
      close: '收盘价',
      high: '最高价',
      low: '最低价',
    },
    fomo_fud_index: {
      project: '项目',
      fomo_fud_chart: '指数曲线',
      fomoIndexDescription: '当前指数',
      floor_price: '地板价',
      one_day_volume: '交易量(24h)',
      one_day_change: '市值变化(24h)',
      one_day_sales: '成交量(24h)',
      one_day_average_price: '交易均价(24h)',
      total_volume: '交易总量',
      total_supply: '供应量',
      num_owners: '持有人总数',
      average_price: '历史均价',
      market_cap: '市值',
    },
    holder_portal_tool: {
      release_time: '发布时间',
    },
    lookalikes: {
      select_one_project_name: '请选一个项目',
      target_project: '目标项目',
      top_5_similar: '最像的5个项目',
    },
    classifier: {
      select_one_project_name: '请选一个项目',
      target_project: '目标项目',
      top_5_similar: '最像的5个项目',
    },
  },
};

export default langData;
