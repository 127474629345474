import React, { FC } from 'react';
import { Carousel } from 'antd';
import ResponsiveContainer from '../responsive-container/responsive-container';
import './slides.less';

const Slides: FC = () => {
  return (
    <ResponsiveContainer>
      <div className="slides__container">
        <Carousel autoplay className="slides__carousel">
          <div className="slides_slide">
            <img src="styled_da_wan_dao.jpeg" alt="slide1" />
          </div>
          <div className="slides_slide">
            <img src="styled_cat_2.png" alt="slide2" />
            {/* <a
              href="https://medium.com/@0xdp.eth/nft2vec-find-nft-collections-with-similar-style-6e9bebe196d4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="styled_cat_2.png" alt="slide2" />
            </a> */}
          </div>
          {/* <div className="slides_slide">
            <img src="wed1.jpg" alt="slide1" />
          </div>
          <div className="slides_slide">
            <img src="wed2.jpg" alt="slide2" />
          </div>
          <div className="slides_slide">
            <img src="wed3.jpg" alt="slide3" />
          </div>
          <div className="slides_slide">
            <img src="wed4.jpg" alt="slide4" />
          </div> */}
        </Carousel>
      </div>
    </ResponsiveContainer>
  );
};

export default Slides;
