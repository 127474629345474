import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../constants/api_endpoints';

const nounce = createApi({
  reducerPath: 'nounce',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT('points'),
  }),
  endpoints: (builder) => ({
    getNonce: builder.query<string, string>({
      query: (address) => '/account/getNonce?publicAddress=' + address,
      transformResponse: (response: any) => response.data,
    }),
  }),
});

export default nounce;
export const { useGetNonceQuery, useLazyGetNonceQuery } = nounce;
