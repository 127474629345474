import { configureStore } from '@reduxjs/toolkit';
import holderPoints from './services/holder-points';
import nounce from './services/nounce';
import login from './services/login';
import userSlice from './slices/user';
import assets from './services/assets';

const store = configureStore({
  reducer: {
    [holderPoints.reducerPath]: holderPoints.reducer,
    [nounce.reducerPath]: nounce.reducer,
    [login.reducerPath]: login.reducer,
    [assets.reducerPath]: assets.reducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
  devTools:
    /* istanbul ignore next line */
    process.env.NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
  enhancers: [],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
