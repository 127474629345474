/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useRef, useReducer } from 'react';
import * as tf from '@tensorflow/tfjs';
import { INCEPTION_CLASSES } from './labels.js';
import ResponsiveContainer from '../responsive-container/responsive-container';
//import './classifier.scoped.css';
import './classifier.less';
const machine = {
  initial: 'initial',
  states: {
    initial: { on: { next: 'loadingModel' } },
    loadingModel: { on: { next: 'modelReady' } },
    modelReady: { on: { next: 'imageReady' } },
    imageReady: { on: { next: 'identifying' }, showImage: true },
    identifying: { on: { next: 'complete' } },
    complete: { on: { next: 'modelReady' }, showImage: true, showResults: true }
  }
};

function classifier() {
  const [results, setResults] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [model, setModel] = useState(null);
  const imageRef = useRef();
  const inputRef = useRef();

  const reducer = (state, event) =>
    machine.states[state].on[event] || machine.initial;

  const [appState, dispatch] = useReducer(reducer, machine.initial);
  const next = () => dispatch('next');

  const loadModel = async () => {
    next();
    const model = await tf.loadGraphModel(
      'https://tfhub.dev/google/tfjs-model/imagenet/inception_v3/classification/3/default/1',
      { fromTFHub: true }
    );
    setModel(model);
    next();
  };

  const identify = async () => {
    next();
    const image = imageRef.current;
    
    const tensorImg = tf.browser.fromPixels(image);
    
    const resizedImg = tf.image
      .resizeBilinear(tensorImg, [299, 299], true)
      .div(255)
      .reshape([1, 299, 299, 3]);

    //console.log('###', resizedImg);
    const predictions = await model.predict(resizedImg);
    
    const { indices } = tf.topk(predictions, 3);

    const results = indices.dataSync();

    console.log(
      'first place:',
      INCEPTION_CLASSES[results[0]],
      'second place:',
      INCEPTION_CLASSES[results[1]],
      'Third place:',
      INCEPTION_CLASSES[results[2]]
    );

    setResults(results);
    next();
  };

  const reset = () => {
    setResults([]);
    next();
  };

  const upload = () => inputRef.current.click();

  const handleUpload = event => {
    const { files } = event.target;
    if (files.length > 0) {
      const url = URL.createObjectURL(event.target.files[0]);
      setImageURL(url);
      next();
    }
  };

  const actionButton = {
    initial: { action: loadModel, text: 'Load Model' },
    loadingModel: { text: 'Loading Model...' },
    modelReady: { action: upload, text: 'Upload Image' },
    imageReady: { action: identify, text: 'Identify Image Content' },
    identifying: { text: 'Identifying...' },
    complete: { action: reset, text: 'Reset' }
  };

  const { showImage, showResults } = machine.states[appState];

  return (
    <ResponsiveContainer>
      <div className='div'>
        {showImage && <img className='img' src={imageURL} alt='upload-preview' ref={imageRef} />}
        <input className='input'
          type='file'
          accept='image/*'
          capture='camera'
          onChange={handleUpload}
          ref={inputRef}
        />
        {showResults && (
          <ul className='ul'>
            <li className='li'>Most likely:
              {INCEPTION_CLASSES[results[0]]}</li>
            <li className='li'>Maybe:
              {INCEPTION_CLASSES[results[1]]}</li>
            <li className='li'>Possibly:
              {INCEPTION_CLASSES[results[2]]}</li>
          </ul>
        )}
        <button className='button' onClick={actionButton[appState].action || (() => ({}))}>
          {actionButton[appState].text}
        </button>
      </div>
    </ResponsiveContainer>
  );
}

export default classifier;
