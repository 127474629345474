import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from '../constants/api_endpoints';

const assets = createApi({
  reducerPath: 'assets',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT('points'),
  }),
  endpoints: (builder) => ({
    getAssets: builder.query<any, object>({
      query: () => ({
        url: '/account/getAssets',
        headers: { 'Access-Token': localStorage.getItem('token') },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export default assets;
export const { useGetAssetsQuery, useLazyGetAssetsQuery } = assets;
