import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../store';
import langData from '../constants/lang-data';
import { createI18n, I18nProvider } from 'react-simple-i18n';
import './app.less';
import Home from './home/home';
import Nav from './nav/nav';
//import Rank from './rank/rank';
import Lookalikes from './lookalikes/index';
import Classifier from './nft_classifier/classifier';
import Footer from './footer/footer';
// import AerfaIndex from './aerfa_index/index';
// import FOMOFUDIndex from './fomo_fud_index/index';
// import HolderPortalMyNfts from './holder-portal/my-nfts';
// import HolderPortalReports from './holder-portal/reports';
// import HolderPortalAnalytics from './holder-portal/analytics';
import { WalletProvider } from '../wallets/WalletProvider';
import { ToastContainer } from 'react-toastify';
import VConsole from 'vconsole';

process.env.NODE_ENV === 'development' && new VConsole();

const App: FC = () => {
  return (
    <Provider store={store}>
      <ToastContainer theme="colored" />
      <I18nProvider i18n={createI18n(langData, { lang: 'en_US' })}>
        <BrowserRouter>
          <WalletProvider>
            <Nav />
            <Routes>
              <Route path="/holder-portal" element={<Navigate replace to="/holder-portal/analytics" />} />
              <Route path="/" element={<Home />} />
              <Route path="/lookalikes" element={<Lookalikes />} />
              <Route path="/classifier" element={<Classifier />} />
              {/* <Route path="/aerfa_index" element={<AerfaIndex />} />
              <Route path="/fomo_fud_index" element={<FOMOFUDIndex />} />
              <Route path="/holder-portal/analytics" element={<HolderPortalAnalytics />} />
              <Route path="/holder-portal/reports" element={<HolderPortalReports />} />
              <Route path="/holder-portal/my-nfts" element={<HolderPortalMyNfts />} /> */}
            </Routes>
            <Footer />
          </WalletProvider>
        </BrowserRouter>
      </I18nProvider>
    </Provider>
  );
};

export default App;
