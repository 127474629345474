export const API_ENDPOINT = (type: string) => {
  const host = window.location.hostname;
  //TODO: this is a workaround, should better use .env
  let env = 'production';
  if (host.includes('develop-www.aerfa.io') || host.includes('localhost')) {
    env = 'development';
  }
  switch (env) {
    case 'development':
      return `https://develop-aerfa-${type}-api.aerfa.io/api`;
    case 'production':
    default:
      if (type === 'points') {
        return 'https://api.aerfa.io/api';
      } else {
        return 'https://aerfa-index.aerfa.io/api';
      }
  }
};
