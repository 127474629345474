import React, { FC, HTMLAttributes } from 'react';
import { Row, Col } from 'antd';
import './responsive-container.less';

const ResponsiveContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <Row justify="center" align="middle" {...props}>
      <Col className="responsive-container__col" xs={24} sm={24} md={20} lg={18} xl={16}>
        {children}
      </Col>
    </Row>
  );
};

export default ResponsiveContainer;
