import React, { FC } from 'react';
import './footer.less';

const Footer: FC = () => {
  return (
    <footer className="footer">
      <p className="footer__copyright">Still Learning 2022. No Rights Reserved. </p>
    </footer>
  );
};

export default Footer;
