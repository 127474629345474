import React, { useState, FC, useEffect } from 'react';
import { StarFilled, UnorderedListOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useI18n } from 'react-simple-i18n';
import ResponsiveContainer from '../responsive-container/responsive-container';
import { Button, Avatar, Tooltip, Dropdown, Menu, Tag, Spin, message } from 'antd';
import './nav.less';
//import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { isMobile } from 'react-device-detect';
// import { useWallet } from '../../wallets/WalletProvider';
// import { useLazyGetLoginQuery } from '../../services/login';
// import { useLazyGetNonceQuery } from '../../services/nounce';
// import { useLazyGetAssetsQuery } from '../../services/assets';
// import { loginSuccessful } from '../../actions/user';
// import { subAddr } from '../../wallets/util';

const getTotalPoints = (state: RootState, contractName: string): number => {
  const nfts = state.user?.assets?.find((asset: any) => asset.contractName === contractName)?.nfts;
  return nfts?.reduce((a: number, b: any) => a + parseFloat(b.hodl_points), 0) || 0;
};

const Nav: FC = () => {
  // const dispatch = useDispatch();
  // const [fetchNounce] = useLazyGetNonceQuery();
  // const [fetchLogin] = useLazyGetLoginQuery();
  // const [fetchAssets] = useLazyGetAssetsQuery();
  // const [isLoading, setLoading] = useState(false);
  // const [checkedUserSession, setCheckedUserSession] = useState(false);
  const { t, i18n } = useI18n();
  const { pathname } = useLocation();
  // const user = useSelector((state: RootState) => state.user);
  // const fdgPoints = useSelector((state: RootState) => getTotalPoints(state, 'FDG'));
  // const zenPoints = useSelector((state: RootState) => getTotalPoints(state, 'Zenlicious'));

  //const { account, onEnable, web3, onDisconnect } = useWallet();

  // const removeToken = () => {
  //   localStorage.removeItem('token');
  // };
  // const handleChangeLanguage = () => {
  //   if (i18n.getLang() === 'en_US') {
  //     i18n.setLang('zh_CN');
  //   } else {
  //     i18n.setLang('en_US');
  //   }
  // };
  // const NavUserDropDown = () => {
  //   return (
  //     <Menu>
  //       <Menu.Item key="fdg">
  //         <div className="flex justify-content-between">
  //           <span>{t('holder_portal.fdg')} &nbsp;</span>
  //           <div>
  //             <Tag color="lime">
  //               <StarFilled /> {fdgPoints.toFixed(2)}
  //             </Tag>
  //           </div>
  //         </div>
  //       </Menu.Item>
  //       <Menu.Item key="zen">
  //         <div className="flex justify-content-between">
  //           <span>{t('holder_portal.zenlicious')} &nbsp;</span>
  //           <div>
  //             <Tag color="lime">
  //               <StarFilled /> {zenPoints.toFixed(2)}
  //             </Tag>
  //           </div>
  //         </div>
  //       </Menu.Item>
  //       <Menu.Item danger key="signout">
  //         <a target="_blank" rel="noopener noreferrer" onClick={onDisconnect}>
  //           {t('nav.signout')}
  //         </a>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };
  // const NavWalletDropDown = () => {
  //   return (
  //     <Menu>
  //       <Menu.Item key="signout">
  //         <a target="_blank" rel="noopener noreferrer" onClick={onDisconnect}>
  //           {t('nav.disconnect')}
  //         </a>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };
  // const chooseWallet = async () => {
  //   await onEnable();
  // };
  // const sign = async () => {
  //   setLoading(true);
  //   const rawNounce = await fetchNounce(account);
  //   if (rawNounce.data) {
  //     let signature;
  //     try {
  //       void message.info(t('nav.user_sign_info'));
  //       signature = await web3.eth.personal.sign(web3.utils.fromUtf8(rawNounce.data), account, '');
  //       void message.info(t('nav.user_logging_in'));
  //     } catch (err: any) {
  //       if (err.code === 4001) {
  //         void message.error(t('nav.user_sign_denied'));
  //       } else {
  //         void message.error(t('nav.user_sign_fail'));
  //       }
  //       return setLoading(false);
  //     }

  //     const loginResponse = await fetchLogin({
  //       publicAddress: account,
  //       signature,
  //       nonce: rawNounce.data,
  //     });
  //     setLoading(false);
  //     if (!loginResponse.data) {
  //       return removeToken();
  //     }
  //     localStorage.setItem('token', loginResponse.data.token);
  //     dispatch(
  //       loginSuccessful({
  //         address: account,
  //         assets: loginResponse.data.holderData,
  //       })
  //     );
  //     void message.success(t('nav.login_success'));
  //   } else {
  //     setLoading(false);
  //   }
  // };
  // const testToken = async () => {
  //   setLoading(true);
  //   const { data } = await fetchAssets(null);
  //   setLoading(false);
  //   if (data?.code === 1) {
  //     return dispatch(
  //       loginSuccessful({
  //         address: account,
  //         assets: data.data,
  //       })
  //     );
  //   } else {
  //     removeToken();
  //   }
  // };

  // useEffect(() => {
  //   async function checkCurrentUserSession() {
  //     const jwtToken = localStorage.getItem('token');
  //     if (jwtToken) {
  //       await testToken();
  //     }
  //     setCheckedUserSession(true);
  //   }
  //   void checkCurrentUserSession();
  // }, []);

  // useEffect(() => {
  //   // 当已连接钱包用户进行如下操作时触发sign步骤：
  //   // 1. 从别的route点击导航进入/holder-potal
  //   // 2. 直接从浏览器地址栏进入/holder-potal
  //   if (pathname.startsWith('/holder-portal/') && checkedUserSession && web3 && account && !user) {
  //     void sign();
  //   }
  // }, [pathname, checkedUserSession, account, web3]);

  // let walletInfo;
  // if (account) {
  //   if (user) {
  //     walletInfo = (
  //       <Dropdown arrow={{ pointAtCenter: true }} overlay={<NavUserDropDown />} placement="bottomRight">
  //         <Avatar className="nav__user-trigger" size={32} src={user?.profileImage} />
  //       </Dropdown>
  //     );
  //   } else {
  //     walletInfo = (
  //       <>
  //         <Dropdown arrow={{ pointAtCenter: true }} overlay={<NavWalletDropDown />} placement="bottomRight">
  //           <span className="operateText">{subAddr(account)}</span>
  //         </Dropdown>
  //         &nbsp;
  //         {isLoading && <Spin />}
  //       </>
  //     );
  //   }
  // } else {
  //   walletInfo = (
  //     <Button type="text" className="operateText" onClick={chooseWallet}>
  //       {isLoading ? <Spin /> : t('nav.connect')}
  //     </Button>
  //   );
  // }

  const menu = (
    <Menu className="nav__menu">
      <Menu.Item key={1}>
        <Link className={''} to="/">
          {t('nav.home')}
        </Link>
      </Menu.Item>
      {/* <Menu.Item key={2}>
        <Link className={''} to="/rank">
          {t('nav.rank')}
        </Link>
      </Menu.Item> */}
      <Menu.Item key={2}>
        <Link className={''} to="/lookalikes">
          {t('nav.lookalikes')}
        </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link className={''} to="/classifier">
          {t('nav.classifier')}
        </Link>
      </Menu.Item>
      {/* <Menu.Item disabled key={3}>
        <Link className={''} to="#">
          {t('nav.raffle')}
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item disabled={!account} key={4}>
        <Link className={''} to={isMobile ? '/holder-portal/my-nfts' : '/holder-portal/analytics'}>
          {t('nav.holder_portal')}
        </Link>
      </Menu.Item> */}
    </Menu>
  );
  const MenuComponent = (
    <>
      <div className="nav__menu flex flex-fill justify-content-between align-items-center nav__desktop_only">
        <div>
          <Link className={`nav__link ${pathname === '/' ? 'nav__link--active' : ''}`} to="/">
            {t('nav.home')}
          </Link>
        </div>
        {/* <div>
          <Link className={`nav__link ${pathname === '/rank' ? 'nav__link--active' : ''}`} to="/rank">
            {t('nav.rank')}
          </Link>
        </div> */}
        <div>
          <Link className={`nav__link ${pathname === '/lookalikes' ? 'nav__link--active' : ''}`} to="/lookalikes">
            {t('nav.lookalikes')}
          </Link>
        </div>
        <div>
          <Link className={`nav__link ${pathname === '/classifier' ? 'nav__link--active' : ''}`} to="/classifier">
            {t('nav.classifier')}
          </Link>
        </div>
        {/* <Tooltip placement="bottom" title={t('nav.coming_soon')}>
          <a role="link" aria-disabled="true" className="nav__link nav__link--disabled">
            {t('nav.raffle')}
          </a>
        </Tooltip> */}
        {/* {account ? (
          <Link
            className={`nav__link ${pathname.startsWith('/holder-portal') ? 'nav__link--active' : ''}`}
            to="/holder-portal"
          >
            {t('nav.holder_portal')}
          </Link>
        ) : (
          <Tooltip placement="bottom" title={t('nav.holder_only')}>
            <a role="link" aria-disabled="true" className="nav__link nav__link--disabled">
              {t('nav.holder_portal')}
            </a>
          </Tooltip>
        )} */}
      </div>
      {/* Mobile */}
      <Dropdown overlay={menu} trigger={['click']} className="nav__mobile_only">
        <a
          className="ant-dropdown-link nav__link"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <UnorderedListOutlined />
        </a>
      </Dropdown>
    </>
  );

  return (
    <ResponsiveContainer className="nav__container">
      <div className="flex width-full justify-content-between">
        <div className="nav__logo flex align-items-center">
          {/* <Link to="/">
            <img
              src="https://cdn.discordapp.com/attachments/943895884352061490/958229237842706462/aerfa_logo_on_black.png"
              alt="Logo"
            />
          </Link> */}
        </div>
        {MenuComponent}
        <div className="flex align-items-center nav__wallet">
          {/* <Button type="text" onClick={handleChangeLanguage} className="operateText">
            {t('nav.lang')}
          </Button> */}
          {/* {walletInfo} */}
        </div>
      </div>
    </ResponsiveContainer>
  );
};

export default Nav;
