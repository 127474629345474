import { createAction } from '@reduxjs/toolkit';

export interface LoginSuccessfulPayload {
  address: string;
  assets: Array<any>;
}

export interface FdgPointsFetchedForUserPayload {
  totalPoints: number;
  tokens: Array<any>;
  profileImage: string;
}

export const loginSuccessful = createAction<LoginSuccessfulPayload>('loginSuccessful');
export const logoutSuccessful = createAction('logoutSuccessful');
export const fdgPointsFetchedForUser = createAction<FdgPointsFetchedForUserPayload>('fdgPointsFetchedForUser');
