import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Web3 from 'web3';
import { logoutSuccessful } from '../actions/user';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Web3Modal from 'web3modal';
import WalletConnect from '@walletconnect/web3-provider';
import { useLocation, useNavigate } from 'react-router-dom';

interface IWLContext {
  wallet: any;
  chain: number;
  web3: any;
  account: string;
  onEnable: () => Promise<void>;
  onDisconnect: any;
}

export const WalletContext = createContext<IWLContext>({} as IWLContext);

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
}

export function WalletProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const wallet = useRef(null);
  const [web3, setWeb3]: any = useState();
  const [account, setAccount] = useState<string>();
  const [chain, setChain] = useState<number>(1);
  const [hasCheckedPersistedSession, setHasCheckedPersistedSession] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const resetApp = () => {
    setAccount('');
    setChain(1);
    localStorage.removeItem('token');
    dispatch(logoutSuccessful());
  };
  const onDisconnect = useCallback(
    async (code?, reason?) => {
      if (pathname.startsWith('/holder-portal')) {
        navigate('/');
      }
      resetApp();
      if (web3 && web3.currentProvider && web3.currentProvider.close) {
        await web3.currentProvider.close();
      }
      await wallet.current.clearCachedProvider();
    },
    [wallet.current, resetApp, web3, pathname]
  );
  const _subscribeToClientEvents = useCallback(
    (provider) => {
      provider.on('accountsChanged', async (accounts: string[]) => {
        if (!accounts[0]) {
          await onDisconnect();
        }
        setAccount(accounts[0]);
      });
      provider.on('chainChanged', (chainId: number) => {
        const net = parseInt(String(chainId), 16);
        if (net === 1) {
          toast.dismiss();
        } else {
          toast('Please change your net to Ethereum Main Net', { type: 'error', autoClose: false });
        }
        //...
      });
      provider.on('disconnect', onDisconnect);
      provider.on('close', onDisconnect);
    },
    [wallet.current, account, onDisconnect]
  );
  const createWalletProvider = useCallback(() => {
    wallet.current = new Web3Modal({
      network: 'mainnet',
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnect,
          options: {
            infuraId: '9c49ff4f0a234457b1fab0591fc4a2a2',
          },
        },
      },
    });
  }, []);
  const onEnable = useCallback(async () => {
    const provider = await wallet.current.connect();
    _subscribeToClientEvents(provider);
    await provider.enable();
    const web3 = initWeb3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.chainId();
    setAccount(accounts[0]);
    setWeb3(web3);
    setChain(chainId);
  }, []);

  const _checkForPersistedSession = useCallback(async () => {
    if (wallet.current.cachedProvider) {
      await onEnable();
    }
  }, []);

  useEffect(() => {
    if (!wallet.current) {
      void createWalletProvider();
    }
  }, []);

  useEffect(() => {
    const getPersistedSession = async () => {
      if (!hasCheckedPersistedSession) {
        await _checkForPersistedSession();
        setHasCheckedPersistedSession(true);
      }
    };

    void getPersistedSession();
  }, []);

  const value = useMemo(
    () => ({
      wallet,
      account,
      web3,
      chain,
      onDisconnect,
      onEnable,
    }),
    [wallet, account, web3, chain, onEnable, onDisconnect]
  );
  return <WalletContext.Provider value={{ ...value }}>{children}</WalletContext.Provider>;
}

export function useWallet() {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWalletConnect must be used within a ContextProvider');
  }
  return context;
}
