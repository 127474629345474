import React, { FC } from 'react';
import { useI18n } from 'react-simple-i18n';
import { Tabs, Row, Col } from 'antd';
import ResponsiveContainer from '../responsive-container/responsive-container';
import Slides from './slides';
import './home.less';

const { TabPane } = Tabs;

const Home: FC = () => {
  const { t } = useI18n();

  return (
    <ResponsiveContainer>
      <div className="home__main">
        <Slides />
      </div>
      {/* <Tabs className="home__tabs" defaultActiveKey="1" animated={true} size="large">
        <TabPane tab={t('home.tab1')} key="1">
          <Row justify="center" align="middle">
            <Col className="home__tab-content flex flex-column" xs={24} sm={24} md={12} lg={12} xl={12}>
              <h4 className="home__tab-content-heading">{t('home.tab1_heading')}</h4>
              <p className="home__tab-content-text">{t('home.tab1_content')}</p>
            </Col>
            <Col className="home__tab-content flex flex-column" xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="home__tab-image">
                <a href="https://opensea.io/collection/fortune-dao-genesis" target="_blank" rel="noopener noreferrer">
                  <img src="fdg.jpeg" alt="slide1" />
                </a>
              </div> 
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={t('home.tab2')} key="2">
          <Row justify="center" align="middle">
            <Col className="home__tab-content flex flex-column" xs={24} sm={24} md={12} lg={12} xl={12}>
              <h4 className="home__tab-content-heading">{t('home.tab2_heading')}</h4>
              <p className="home__tab-content-text">{t('home.tab2_content')}</p>
            </Col>
            <Col className="home__tab-content flex flex-column" xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="home__tab-image">
                <a href="http://zenlicious.io/" target="_blank" rel="noopener noreferrer">
                  <img src="zenlicious.jpeg" alt="slide1" />
                </a>
              </div> 
            </Col>
          </Row>
        </TabPane>
      </Tabs> */}
    </ResponsiveContainer>
  );
};

export default Home;
